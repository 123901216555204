import Vue from 'vue'
import db from '@/firebase/init'
import firebase from 'firebase'
import { omit, random, intersection, orderBy } from 'lodash'
import router from '@/router'
import { loadStripe } from '@stripe/stripe-js';

var user

firebase.auth().onAuthStateChanged(() => {
  user = firebase.auth().currentUser
})

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  plans: [],
  meals: [],
  folders: [],
  allMeals: [],
  testValue: null,
  rejectedPlans: [],
  requestedPlans: [],
  approvedPlans: [],
  currentFolder: null,
  status: {
    error: null,
    adding: false,
    moving: false,
    getting: false,
    deleting: false,
    updating: false,
    inserting: false,
    generating: false,
    duplicating: false,
    deletingPlan: false,
    updatingMeal: false,
    deletingFolder: false,
    creatingFolder: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  getPlanName: (state) => (id) => {
    if (state.plans) {
      let plan = state.plans.find(plan => plan.id == id)
      return plan || {}
    }
    else return {}
  },
  getRecipeCount: (state) => (id) => {
    if (state.allMeals.length) {
      return state.allMeals.filter((v) => (v === id)).length;
    }
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  updatingState(state, bol) {
    state.status.updating = bol
  },

  duplicatingState(state, bol) {
    state.status.duplicating = bol
  },

  setPlans(state, payload) {
    state.plans = []

    payload.forEach(plan => {
      let data = Vue.prototype.$formatData(plan)
      state.plans.push(data)
    })

    state.status.getting = false
  },
  setFolders(state, payload) {
    state.folders = []

    let f = router.currentRoute.query?.folder

    if (f) {
      let currentFolder
      payload.forEach(folder => {
        let data = Vue.prototype.$formatData(folder)
        state.folders.push(data)

        if (data.id == f) {
          currentFolder = data
        }
      })

      if (currentFolder) state.currentFolder = currentFolder
      else state.currentFolder = Vue.prototype.$formatData(payload.docs[0])

    } else {
      state.currentFolder = Vue.prototype.$formatData(payload.docs[0])
      payload.forEach(folder => {
        let data = Vue.prototype.$formatData(folder)
        state.folders.push(data)
      })
    }

    state.status.getting = false
  },

  setCurrentFolderToOthers() {
    state.currentFolder = { id: "1", client: 'Others' }
  },

  insertPlan(state, payload) {
    let data

    try {
      data = Vue.prototype.$formatData(payload)
    }
    catch {
      data = payload
    }

    if (!state.plans.find(p => p.id == data.id))
      state.plans.push(data)
  },

  insertFolder(state, payload) {
    let data

    try {
      data = Vue.prototype.$formatData(payload)
    }
    catch {
      data = payload
    }

    if (!state.folders.find(p => p.id == data.id))
      state.folders.push(data)
  },

  removePlan(state, plan) {
    state.plans.splice(state.plans.indexOf(plan), 1)
  },

  addingState(state, bol) {
    state.status.adding = bol
  },

  creatingFolderState(state, bol) {
    state.status.creatingFolder = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  insertingRecipe(state, bol) {
    state.status.inserting = bol
  },

  pushMeal(state, payload) {
    let meal = state.meals.find(meal => meal.id == payload.id)
    if (!meal) {
      state.meals.push(payload)
    }
    else {
      let index = state.meals.indexOf(meal)
      Vue.set(state.meals, index, payload)
    }
  },

  setMeals(state, payload) {
    state.meals = []

    if (payload.size || payload.length) {
      payload.forEach(doc => {
        let data = Vue.prototype.$formatData(doc)
        state.meals.push(data)
      })
    }
  },

  clearAllMeals(state) {
    state.allMeals = []
  },

  setAllMeals(state, payload) {
    state.allMeals.push(payload)
  },

  removeFromAllMeals(state, payload) {
    const index = state.allMeals.indexOf(payload);
    if (index > -1) {
      state.allMeals.splice(index, 1);
    }
  },

  setTestValue(state, payload) {
    state.testValue = payload
  },

  removedMeal(state, payload) {
    state.meals.splice(state.meals.indexOf(payload), 1)
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  deletingPlan(state, bol) {
    state.status.deletingPlan = bol
  },

  setData(state, payload) {
    Object.assign(state.data, payload)
  },

  renamePlan(state, payload) {
    let plan = state.plans.find(plan => plan.id == payload.id)
    let index = state.plans.indexOf(plan)
    plan.name = payload.name

    Vue.set(state.plans, index, plan)
    // Object.assign(state.data, {})
  },

  setHasDownloaded(state, id) {
    let plan = state.plans.find(plan => plan.id == id)
    let index = state.plans.indexOf(plan)

    plan.hasDownloaded = true

    Vue.set(state.plans, index, plan)
    Object.assign(state.data, {})
  },

  generatingState(state, bol) {
    state.status.generating = bol
  },

  updateField(state, payload) {
    Vue.set(payload.meal, payload.field, payload.value)
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  updateNutritionInfo(state, payload) {
    const currentPlan = state.plans.find(plan => plan.id === payload.planId)

    currentPlan[payload.name] = payload.value
  },
  addedToMarketplace(state, payload) {
    const currentPlan = state.plans.find(plan => plan.id === payload)

    currentPlan['addedToMarketplace'] = true
  },
  removeMarketplace(state, payload) {
    const currentPlan = state.plans.find(plan => plan.id === payload)

    currentPlan['addedToMarketplace'] = false
  },

  setServingValue(state, payload) {
    const currentPlan = state.plans.find(plan => plan.id === payload.id)

    payload.meals.forEach(d => {
      console.log(d)
      currentPlan[`serving_${d}`] = 1
    })

  },

  setServingSnackVal(state, payload) {
    const currentPlan = state.plans.find(plan => plan.id === payload.id)

    for (let index = 1; index <= payload.snacks; index++) {
      currentPlan[`serving_snack${index}`] = 1
    }

  },

  setGenerateLinkDetails(state, payload) {
    const currentPlan = state.plans.find(plan => plan.id === payload.id)

    currentPlan.link = payload.link
    currentPlan.linkDetails = payload.details
  },

  setGenerateMealplanLinkDetails(state, payload) {
    const currentPlan = state.plans.find(plan => plan.id === payload.id)

    currentPlan.mealplanLink = payload.link
    currentPlan.linkDetails = payload.details
  },

  selectFolder(state, payload) {
    state.currentFolder = payload
  },

  addedPlanToFolder(state, payload) {
    const folder = state.folders.find(f => f.id == payload.folder.id)
    folder.mealplans.push(payload.id)

    if (state.currentFolder.id == payload.folder.id)
      state.currentFolder.mealplans.push(payload.id)
  },
  moveSelectedMealplan(state, payload) {

    // REMOVE MEALPLAN FROM CURRENT FOLDER
    if (state.currentFolder.id != '1' && state.currentFolder.id != '2')
      state.currentFolder.mealplans = state.currentFolder.mealplans.filter(m => m != payload.mealplanId)

    // MOVE TO DESIGNATED FOLDER
    const folder = state.folders.find(f => f.id == payload.folderId)

    folder.mealplans.push(payload.mealplanId)

    const plan = state.plans.find(p => p.id == payload.mealplanId)

    plan.folderId = payload.folderId

    const tempPlans = state.plans
    state.plans = []

    state.plans = tempPlans
  },

  setMoveStatus(state, bol) {
    state.status.moving = bol
  },

  setMealplanName(state, payload) {
    state.data.name = payload
  },

  updateFolderName(state, payload) {
    console.log(payload)
    const folder = state.folders.find(p => p.id == payload.id)
    folder.client = payload.name
    state.currentFolder.client = payload.name
  },

  setDeletingFolder(state, payload) {
    state.status.deletingFolder = payload
  },
  removeFolder(state, folder) {
    state.folders.splice(state.folders.indexOf(folder), 1)
  },

  setReqPlans(state, payload) {
    state.requestedPlans = []

    payload.forEach(plan => {
      let data = Vue.prototype.$formatData(plan)
      state.requestedPlans.push(data)
    })

    state.status.getting = false
  },
  setRejPlans(state, payload) {
    state.rejectedPlans = []

    payload.forEach(plan => {
      let data = Vue.prototype.$formatData(plan)
      state.rejectedPlans.push(data)
    })

    state.status.getting = false
  },
  setApprovedPlans(state, payload) {
    state.approvedPlansPlans = []

    payload.forEach(plan => {
      let data = Vue.prototype.$formatData(plan)
      state.approvedPlans.push(data)
    })

    state.status.getting = false
  },
  requestToSuccess(state, payload) {
    let plan = state.requestedPlans.find((r) => r.id == payload.id);
    state.requestedPlans.splice(state.requestedPlans.indexOf(plan), 1);
    plan.requestStatus = 'approved';

    state.approvedPlans.push(plan);
  },
  requestToDeclined(state, payload) {
    let plan = state.requestedPlans.find((r) => r.id == payload.id);
    state.requestedPlans.splice(state.requestedPlans.indexOf(plan), 1);
    plan.requestStatus = 'rejected';

    state.rejectedPlans.push(plan);
  },
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET PLANS
   *----------------------------------------------------------------------------*/
  async getPlans({ commit, dispatch }) {
    commit('gettingState', true)
    let user = firebase.auth().currentUser

    await db.collection('mealPlans')
      .where('user', '==', user.uid)
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        if (snapshot.size)
          commit('setPlans', snapshot)
        else
          commit('gettingState', false)
      })
      .catch(error => {
        console.log(error.message)
        commit('gettingState', false)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * GET REQUESTED PLANS
   *----------------------------------------------------------------------------*/
  async getReqPlans({ commit, dispatch }) {
    commit('gettingState', true)

    await db.collection('mealPlans')
      .where('requestStatus', '==', 'requesting')
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        if (snapshot.size)
          commit('setReqPlans', snapshot)
        else
          commit('gettingState', false)
      })
      .catch(error => {
        console.log(error.message)
        commit('gettingState', false)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * GET REQUESTED PLANS
   *----------------------------------------------------------------------------*/
  async getRejPlans({ commit, dispatch }) {
    commit('gettingState', true)

    await db.collection('mealPlans')
      .where('requestStatus', '==', 'rejected')
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        if (snapshot.size)
          commit('setRejPlans', snapshot)
        else
          commit('gettingState', false)
      })
      .catch(error => {
        console.log(error.message)
        commit('gettingState', false)
        dispatch('showError', error.message, { root: true })
      })
  },
  /*------------------------------------------------------------------------------
   * GET APPROVED PLANS
   *----------------------------------------------------------------------------*/
  async getApprovedPlans({ commit, dispatch }) {
    commit('gettingState', true)

    await db.collection('mealPlans')
      .where('requestStatus', '==', 'approved')
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        if (snapshot.size)
          commit('setApprovedPlans', snapshot)
        else
          commit('gettingState', false)
      })
      .catch(error => {
        console.log(error.message)
        commit('gettingState', false)
        dispatch('showError', error.message, { root: true })
      })
  },


  /*------------------------------------------------------------------------------
   * GET PLANS FROM FORM
   *----------------------------------------------------------------------------*/
  async getPlansFromForm({ commit, dispatch }, user) {
    commit('gettingState', true)

    await db.collection('mealPlans')
      .where('user', '==', user.id)
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        if (snapshot.size)
          commit('setPlans', snapshot)
        else
          commit('gettingState', false)
      })
      .catch(error => {
        console.log(error.message)
        commit('gettingState', false)
        dispatch('showError', error.message, { root: true })
      })
  },


  /*------------------------------------------------------------------------------
   * GET PLAN BY ID
   *----------------------------------------------------------------------------*/
  async getPlan({ commit, dispatch }, id) {
    await db.collection('mealPlans')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) commit('insertPlan', doc)
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * ADD PLAN
   *----------------------------------------------------------------------------*/
  async create({ state, commit, dispatch }, payload = null) {
    commit('addingState', true)
    if (state.status.error) commit('setError', null)

    let data
    let route = router.currentRoute

    if (!payload)
      data = Object.assign({}, state.data)
    else
      data = Object.assign({}, payload)

    data.user = user.uid
    data.createdAt = Date.now()
    data.serving_meal = 1
    data.serving_breakfast = 1
    data.serving_dinner = 1
    data.serving_desert = 1
    data.serving_lunch = 1
    data.serving_snack1 = 1
    data.serving_snack2 = 1
    data.serving_snack3 = 1
    data.serving_snack4 = 1
    data.noMeals = false
    data.requestStatus = ""

    if(Object.prototype.hasOwnProperty.call(data, "recipeplan"))
      data.recipeplan = payload?.recipeplan
    else
      data.recipeplan = route.name == 'RecipePlans'

    if (!data?.folderId)
      data.folderId = state.currentFolder.id

    const res = await db.collection('mealPlans')
      .add(data)
      .then((docRef) => {
        commit('addingState', false)

        if (!payload?.silent)
          dispatch('showSuccess', 'Meal plan added.', { root: true })

        let newData = data
        newData.ref = docRef
        newData.id = docRef.id

        commit('insertPlan', newData)
        return { newData, error: false }
      })
      .catch(error => {
        commit('addingState', false)
        commit('setError', error.message)
        return { error: true }
      })

    return res
  },

  /*------------------------------------------------------------------------------
   * ADD PLAN FROM FORM
   *----------------------------------------------------------------------------*/
  async createFromForm({ state, commit }, payload = null) {
    commit('addingState', true)
    if (state.status.error) commit('setError', null)

    let data

    data = Object.assign({}, payload)

    data.user = payload.id
    data.createdAt = Date.now()
    data.serving_meal = 1
    data.serving_breakfast = 1
    data.serving_dinner = 1
    data.serving_desert = 1
    data.serving_lunch = 1
    data.serving_snack1 = 1
    data.serving_snack2 = 1
    data.serving_snack3 = 1
    data.serving_snack4 = 1
    data.mealsLoaded = false
    data.noMeals = false
    data.fromForm = true

    const res = await db.collection('mealPlans')
      .add(data)
      .then((docRef) => {
        commit('addingState', false)

        let newData = data
        newData.ref = docRef
        newData.id = docRef.id

        return { newData, error: false, data }
      })
      .catch(error => {
        commit('addingState', false)
        commit('setError', error.message)
        return { error: true }
      })

    return res
  },

  /*------------------------------------------------------------------------------
   * UPDATE PLAN NAME
   *----------------------------------------------------------------------------*/
  async updateName({ state, commit, dispatch }, plan) {
    commit('updatingState', true)


    if (state.status.error) commit('setError', null)
    let hasError = false

    let data = plan
    data.updatedAt = Date.now()

    await data.ref.update(omit({ name: state.data.name }, ['id', 'ref']))
      .then(() => {
        commit('updatingState', false)
        commit('renamePlan', { id: plan.id, name: state.data.name })
        dispatch('showSuccess', 'Mealplan name updated.', { root: true })
      })
      .catch(error => {
        commit('updatingState', false)
        commit('setError', error.message)
        hasError = true
      })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * DELETE PLAN
   *----------------------------------------------------------------------------*/
  async deletePlan({ commit, dispatch }, plan) {
    commit('deletingPlan', true)

    let batch = db.batch()

    await plan.ref
      .collection('meals')
      .get()
      .then(async (snapshot) => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            batch.delete(doc.ref)
          })

          await batch.commit()
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
      .finally(async () => {
        await plan.ref.delete()
          .then(() => {
            commit('removePlan', plan)
            commit('deletingPlan', false)
            dispatch('showSuccess', 'Plan successfully deleted.', { root: true })
          })
          .catch(error => {
            dispatch('showError', error.message, { root: true })
            commit('deletingPlan', false)
            console.log(error.message)
          })
      })
  },

  /*------------------------------------------------------------------------------
   * DELETE FOLDER & PLANS
   *----------------------------------------------------------------------------*/
  async deleteFolder({ commit, dispatch }, plans) {
    commit('setDeletingFolder', true)

    plans.forEach(async (plan) => {
      let batch = db.batch()

      await dispatch('goal/deleteGoalByUser', plan.id, { root: true })
      await plan.ref
        .collection('meals')
        .get()
        .then(async (snapshot) => {
          if (snapshot.size) {
            snapshot.forEach(doc => {
              batch.delete(doc.ref)
            })

            await batch.commit()
          }
        })
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
        })
        .finally(async () => {
          await plan.ref.delete()
            .then(() => {
              commit('removePlan', plan)
            })
            .catch(error => {
              dispatch('showError', error.message, { root: true })
              console.log(error.message)
            })
        })
    })

    await state.currentFolder.ref.delete()
      .then(() => {
        commit('removeFolder', this.currentFolder)
        dispatch('showSuccess', 'Folder successfully deleted.', { root: true })
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        commit('deletingPlan', false)
        console.log(error.message)
      })
      .finally(() => {
        commit('setDeletingFolder', false)
      })
  },


  /*------------------------------------------------------------------------------
   * INSERT MEAL IN PLAN TABLE
   * $data: Object
   *  day: String
   *  meal: String
   *  recipe: Object
   *  serves: Number (optional)
   * $data: Object
   *  day: String
   *  meal: String
   *  recipe: Object
   *  serves: Number (optional)
   *----------------------------------------------------------------------------*/
  async insertMeal({ commit, dispatch }, data) {
    commit('insertingRecipe', true)
    let plan = data.cell.plan, ref
    let meal = data.cell.meal === 'dessert' ? 'desert' : data.cell.meal

    let newData = {
      meal,
      user: user.uid,
      day: data.cell.day,
      createdAt: Date.now(),
      recipe: data.recipe.id,
      origin: data.origin || null,
      originData: data.originData || null,
      serves: data.serves || plan[`serving_${meal}`],
    }

    if (data.cell.id) ref = plan.ref.collection('meals').doc(data.cell.id)
    else ref = plan.ref.collection('meals').doc()

    await ref.set(newData).then(() => {
      commit('insertingRecipe', false)

      let data = newData
      data.ref = ref
      data.id = ref.id
      commit('pushMeal', data)
    })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        commit('insertingRecipe', false)
      })
  },

  /*------------------------------------------------------------------------------
   * INSERT BLANK MEAL
   *----------------------------------------------------------------------------*/
  insertBlankMeal({ commit, dispatch }, data) {
    let newData = data
    let plan = data.plan
    newData.user = user.uid
    newData.recipe = 'no meal'
    newData.createdAt = Date.now()

    if (plan) {
      plan.ref.collection('meals')
        .add(omit(newData, ['plan']))
        .then((docRef) => {
          newData.ref = docRef
          newData.id = docRef.id
          commit('pushMeal', data)
        })
        .catch(error => {
          dispatch('showError', error.message, { root: true })
        })
    }
  },

  /*------------------------------------------------------------------------------
   * GET MEAL
   *----------------------------------------------------------------------------*/
  getMeal({ commit, dispatch }, id) {
    db.collection('meals')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) {
          let data = Vue.prototype.$formatData(doc)
          commit('pushMeal', data)
        }
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * SET MEALS
   *----------------------------------------------------------------------------*/
  async getMeals({ commit, dispatch }, plan) {
    await plan.ref
      .collection('meals')
      .get()
      .then(snapshot => {
        if (snapshot.size)
          commit('setMeals', snapshot)
        else
          commit('setMeals', [])
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * GET MEALS FOR PROMO EBOOK
   *----------------------------------------------------------------------------*/
  async getMealsEbook({ commit, dispatch }, plan) {
    return await plan.ref
      .collection('meals')
      .get()
      .then(snapshot => {
        let meals = []
        if (snapshot.size) {
          commit('setMeals', snapshot)
          if (snapshot.size || snapshot.length) {
            snapshot.forEach(doc => {
              let data = Vue.prototype.$formatData(doc)
              meals.push(data)
            })
          }
          return meals
        }
        else
          return []
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * REMOVE MEAL
   *----------------------------------------------------------------------------*/
  async delete({ commit, dispatch }, meal) {
    commit('deletingState', true)

    await meal.ref.delete()
      .then(() => {
        dispatch('showSuccess', 'Meal successfully removed.', { root: true })
        commit('removedMeal', meal)
        commit('deletingState', false)
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        commit('deletingState', false)
        console.log(error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * DELETE MEALS BY USER
   *----------------------------------------------------------------------------*/
  async deleteMealsByUser({ dispatch }, user) {
    await db.collection('mealPlans')
      .where('user', '==', user)
      .get()
      .then(async (snapshot) => {
        if (snapshot.size) {
          let batch = db.batch()

          snapshot.forEach(doc => {
            batch.delete(doc.ref)
            dispatch('batchDeleteMeals', doc)
          })

          await batch.commit()
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * AUTO GENERATE MEALS
   *----------------------------------------------------------------------------*/
  async autoGenerate({ commit, dispatch, rootState, rootGetters }, { plan, roleType }) {

    commit('generatingState', true)
    let user = rootState.user.user

    let goal = rootState.goal.goal
    let mainMeals = goal.mainMeals
    let mainEnergy = rootGetters['goal/mainMealsEnergy']
    let snackEnergy = rootGetters['goal/snacksEnergy']

    if (!mainMeals.length) {
      dispatch('showError', 'Please select at least one main meal', { root: true })
      return
    }

    let meals = []

    mainEnergy = parseInt(mainEnergy)
    snackEnergy = parseInt(snackEnergy)

    // GET USERS
    let users = []
    const snapshot = await db.collection('users').where('status', '==', 'active').get()

    if (snapshot.size) {
      snapshot.forEach(user => {
        let data = Vue.prototype.$formatData(user)
        users.push(data)
      })
    }

    // GET ALL PUBLISHED RECIPES
    db.collection('recipes')
      .where('status', '==', 'published')
      .get()
      .then(async snapshot => {
        if (snapshot.size) {
          let recipes = []

          // STORE INSIDE RECIPES VARIABLE
          snapshot.forEach(doc => {
            let recipe = doc.data()
            recipe.ref = doc.ref
            recipe.id = doc.id
            recipes.push(recipe)
          })


          // FILTER RECIPE BY ROLE TYPE
          if (roleType != 'all') {
            let filtered = recipes
            recipes = []
            recipes = filtered.filter(recipe => {
              let member = users.find(user => user.id == recipe.user)

              if (member?.role == roleType)
                return recipe
            })
          }

          // CHECK IF GOAL INCLUDES SNACK
          if (goal.snacks) {
            for (let i = 1; i <= goal.snacks; i++) {
              mainMeals = mainMeals.concat([`snack${i}`])
            }
          }

          // CHECK FOR GOAL MAIN MEALS
          if (mainMeals) {
            mainMeals.forEach((mainMeal) => {
              for (let day = 1; day <= 7; day++) {
                // FILTER RECIPES BY MEAL TIME
                let findRecipes = recipes.filter(recipe => {
                  let mealTime = []

                  if (recipe.mealTime) {
                    mealTime = recipe.mealTime.map(data => {
                      return data.toLowerCase()
                    })
                  }

                  let time = mainMeal.includes('snack') ? 'snack' : mainMeal
                  if (time == 'desert') time = 'dessert'
                  return mealTime.includes(time)
                })

                // FILTER RECIPE WITHIN GOAL FOR MAIN MEALS
                if (!mainMeal.includes('snack') && mainMeal !== 'desert') {
                  findRecipes = findRecipes.filter(recipe => {
                    return recipe.servingValues.energy >= (mainEnergy - (mainEnergy * 0.15)) && recipe.servingValues.energy <= (mainEnergy + (mainEnergy * 0.15))
                  })
                }
                else {
                  findRecipes = findRecipes.filter(recipe => {
                    return recipe.servingValues.energy >= (snackEnergy - (snackEnergy * 0.25)) && recipe.servingValues.energy <= (snackEnergy + (snackEnergy * 0.25))
                  })
                }

                var filter = ['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'].includes(user.role) ? plan : user

                // NUTRTION TAGS FILTER
                if (filter.nutritionTags && filter.nutritionTags.length) {
                  findRecipes = findRecipes.filter(recipe => {
                    let count = intersection(recipe.nutritionTags, filter.nutritionTags).length
                    return count == filter.nutritionTags.length
                  })
                }

                // MEAL TAGS FILTER
                if (filter.mealTags && filter.mealTags.length) {
                  findRecipes = findRecipes.filter(recipe => {
                    let count = intersection(recipe.tags, filter.mealTags).length
                    return count == filter.mealTags.length
                  })
                }

                // INGREDIENTS
                if (filter.ingredientTags && filter.ingredientTags.length) {
                  findRecipes = findRecipes.filter((recipe) => {
                    let excluded = false

                    filter.ingredientTags.forEach(tag => {
                      if (recipe.name.toLowerCase().includes(tag.toLowerCase()))
                        excluded = true

                      recipe.ingredients.forEach(ingredient => {
                        if (ingredient.food.toLowerCase().includes(tag.toLowerCase()))
                          excluded = true
                      })
                    })

                    return !excluded
                  })
                }

                // FILTER BY UNIQUE RECIPES
                var uniqueRecipes = findRecipes.filter(recipe => {
                  return !meals.find(m => m.recipe == recipe.id)
                })

                findRecipes = uniqueRecipes.length ? uniqueRecipes : findRecipes

                //  FILTER BY UNIQ RECIPE AND DAY
                uniqueRecipes = findRecipes.filter(recipe => {
                  return !meals.find(m => m.recipe == recipe.id && m.day == day)
                })

                findRecipes = uniqueRecipes.length ? uniqueRecipes : findRecipes

                //  FILTER BY UNIQ RECIPE AND MEAL
                uniqueRecipes = findRecipes.filter(recipe => {
                  return !meals.find(m => m.recipe == recipe.id && m.meal == mainMeal)
                })

                findRecipes = uniqueRecipes.length ? uniqueRecipes : findRecipes

                let count = findRecipes.length
                let rand = 0

                if (count > 1) {
                  rand = random(0, count - 1)
                }

                if (findRecipes[rand]) {
                  let newData = {
                    day,
                    locked: true,
                    user: user.id,
                    meal: mainMeal,
                    recipe: findRecipes[rand].id,
                    serves: plan[`serving_${mainMeal}`] || 1,
                    createdAt: firebase.firestore.Timestamp.now(),
                  }

                  meals.push(newData)
                }

              }
            })
          }

          let batch = db.batch()

          meals.forEach(meal => {
            let exists = state.meals.find(m => {
              return meal.meal == m.meal && meal.day == m.day
            })

            if (!exists || (exists && !exists.locked) || (exists && exists.recipe == 'no-meal')) {
              let ref = exists ? plan.ref.collection('meals').doc(exists.id) : plan.ref.collection('meals').doc()
              batch.set(ref, meal)
            }
          })

          batch.commit()
            .then(() => {
              if (meals.length) {
                dispatch('updateMealField', {
                  value: false,
                  field: 'noMeals',
                  meal: plan,
                  silent: true
                })
                dispatch('showSuccess', 'Meals successfully added.', { root: true })
              }
              else {
                dispatch('updateMealField', {
                  value: true,
                  field: 'noMeals',
                  meal: plan,
                  silent: true
                })
                dispatch('showError', 'There are not enough recipes to meet the requested criteria', { root: true })
              }
              dispatch('getMeals', plan)
              dispatch('getAllMeals', [{ ...plan }])
              commit('generatingState', false)
            })
        }
      })
      .catch(error => {
        console.log(error.message)
      })

  },

  /*------------------------------------------------------------------------------
   * GENERATE MEALS FROM FORM
   *----------------------------------------------------------------------------*/
  async autoGenerateFromForm({ commit, dispatch, rootState, rootGetters }, { plan, roleType, user }) {
    console.log({ plan })
    return new Promise((resolve, reject) => {
      commit('generatingState', true)

      let goal = rootState.goal.goal
      let mainMeals = goal.mainMeals
      let mainEnergy = rootGetters['goal/mainMealsEnergy']
      let snackEnergy = rootGetters['goal/snacksEnergy']

      if (!mainMeals.length) {
        dispatch('showError', 'Please select at least one main meal', { root: true })
        return
      }

      let meals = []

      mainEnergy = parseInt(mainEnergy)
      snackEnergy = parseInt(snackEnergy)

      // GET USERS
      let users = []
      db.collection('users').where('status', '==', 'active').get().then((snapshot) => {
        if (snapshot.size) {
          snapshot.forEach(user => {
            let data = Vue.prototype.$formatData(user)
            users.push(data)
          })

          // GET ALL PUBLISHED RECIPES
          db.collection('recipes')
            .where('status', '==', 'published')
            .get()
            .then(async snapshot => {
              if (snapshot.size) {
                let recipes = []

                // STORE INSIDE RECIPES VARIABLE
                snapshot.forEach(doc => {
                  let recipe = doc.data()
                  recipe.ref = doc.ref
                  recipe.id = doc.id
                  recipes.push(recipe)
                })


                // FILTER RECIPE BY ROLE TYPE
                if (roleType != 'all') {
                  let filtered = recipes
                  recipes = []
                  recipes = filtered.filter(recipe => {
                    let member = users.find(user => user.id == recipe.user)

                    if (member?.role == roleType)
                      return recipe
                  })
                }

                // CHECK IF GOAL INCLUDES SNACK
                if (goal.snacks) {
                  for (let i = 1; i <= goal.snacks; i++) {
                    mainMeals = mainMeals.concat([`snack${i}`])
                  }
                }

                // CHECK FOR GOAL MAIN MEALS
                if (mainMeals) {
                  mainMeals.forEach((mainMeal) => {
                    for (let day = 1; day <= 7; day++) {
                      // FILTER RECIPES BY MEAL TIME
                      let findRecipes = recipes.filter(recipe => {
                        let mealTime = []

                        if (recipe.mealTime) {
                          mealTime = recipe.mealTime.map(data => {
                            return data.toLowerCase()
                          })
                        }

                        let time = mainMeal.includes('snack') ? 'snack' : mainMeal
                        if (time == 'desert') time = 'dessert'
                        return mealTime.includes(time)
                      })

                      // FILTER RECIPE WITHIN GOAL FOR MAIN MEALS
                      if (!mainMeal.includes('snack') && mainMeal !== 'desert') {
                        findRecipes = findRecipes.filter(recipe => {
                          return recipe.servingValues.energy >= (mainEnergy - (mainEnergy * 0.15)) && recipe.servingValues.energy <= (mainEnergy + (mainEnergy * 0.15))
                        })
                      }
                      else {
                        findRecipes = findRecipes.filter(recipe => {
                          return recipe.servingValues.energy >= (snackEnergy - (snackEnergy * 0.25)) && recipe.servingValues.energy <= (snackEnergy + (snackEnergy * 0.25))
                        })
                      }

                      var filter = plan

                      // NUTRTION TAGS FILTER
                      if (filter.nutritionTags && filter.nutritionTags.length) {
                        findRecipes = findRecipes.filter(recipe => {
                          let count = intersection(recipe.nutritionTags, filter.nutritionTags).length
                          return count == filter.nutritionTags.length
                        })
                      }

                      // MEAL TAGS FILTER
                      if (filter.mealTags && filter.mealTags.length) {
                        findRecipes = findRecipes.filter(recipe => {
                          let count = intersection(recipe.tags, filter.mealTags).length
                          return count == filter.mealTags.length
                        })
                      }

                      // INGREDIENTS
                      if (filter.ingredientTags && filter.ingredientTags.length) {
                        findRecipes = findRecipes.filter((recipe) => {
                          let excluded = false

                          filter.ingredientTags.forEach(tag => {
                            if (recipe.name.toLowerCase().includes(tag.toLowerCase()))
                              excluded = true

                            recipe.ingredients.forEach(ingredient => {
                              if (ingredient.food.toLowerCase().includes(tag.toLowerCase()))
                                excluded = true
                            })
                          })

                          return !excluded
                        })
                      }

                      // FILTER BY UNIQUE RECIPES
                      var uniqueRecipes = findRecipes.filter(recipe => {
                        return !meals.find(m => m.recipe == recipe.id)
                      })

                      findRecipes = uniqueRecipes.length ? uniqueRecipes : findRecipes

                      //  FILTER BY UNIQ RECIPE AND DAY
                      uniqueRecipes = findRecipes.filter(recipe => {
                        return !meals.find(m => m.recipe == recipe.id && m.day == day)
                      })

                      findRecipes = uniqueRecipes.length ? uniqueRecipes : findRecipes

                      //  FILTER BY UNIQ RECIPE AND MEAL
                      uniqueRecipes = findRecipes.filter(recipe => {
                        return !meals.find(m => m.recipe == recipe.id && m.meal == mainMeal)
                      })

                      findRecipes = uniqueRecipes.length ? uniqueRecipes : findRecipes

                      let count = findRecipes.length
                      let rand = 0

                      if (count > 1) {
                        rand = random(0, count - 1)
                      }

                      if (findRecipes[rand]) {
                        let newData = {
                          day,
                          locked: true,
                          user: user.id,
                          meal: mainMeal,
                          recipe: findRecipes[rand].id,
                          serves: plan[`serving_${mainMeal}`] || 1,
                          createdAt: firebase.firestore.Timestamp.now(),
                        }

                        meals.push(newData)
                      }

                    }
                  })
                }

                let batch = db.batch()

                meals.forEach(meal => {
                  let exists = state.meals.find(m => {
                    return meal.meal == m.meal && meal.day == m.day
                  })

                  if (!exists || (exists && !exists.locked) || (exists && exists.recipe == 'no-meal')) {
                    let ref = exists ? plan.ref.collection('meals').doc(exists.id) : plan.ref.collection('meals').doc()
                    batch.set(ref, meal)
                  }
                })

                batch.commit()
                  .then(() => {
                    dispatch('getMeals', plan)
                    dispatch('getAllMeals', [{ ...plan }])
                    // dispatch('showSuccess', 'Meals successfully added.', { root: true })
                    commit('generatingState', false)
                    resolve()
                  })
              }
            })
            .catch(error => {
              console.log(error.message)
              reject()
            })

        }
      })

    })

  },

  async duplicateMeal(p, payload) {

    let batch = db.batch()
    payload.meals.forEach(meal => {
      let ref = payload.plan.ref.collection('meals').doc()
      batch.set(ref, meal)
    })

    await batch.commit()
  },

  /*------------------------------------------------------------------------------
   * UPDATE MEAL FIELD
   * 
   * @params
   *  Object
   *    meal: Object
   *    field: String
   *    value: Any
   *    silent: Boolean (optional)
   *    message: String (optional)
   *----------------------------------------------------------------------------*/
  async updateMealField({ commit, dispatch }, data) {
    commit('updateStatus', { updatingMeal: true })

    await data.meal.ref
      .update({
        [data.field]: data.value,
        updated: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        commit('updateField', data)
        if (!data.silent) dispatch('showSuccess', data.message || 'Mealplanplan updated', { root: true })
      })
      .catch(error => {
        console.log(error.message)
      })
      .finally(() => {
        commit('updateStatus', { updatingMeal: false })
      })
      .finally(() => {
        commit('updateStatus', { updatingMeal: false })
      })
  },

  /*------------------------------------------------------------------------------
   * COUNT DOWNLOAD
   * @params
   *  plan: Object
   *----------------------------------------------------------------------------*/
  countDownload({ dispatch, rootState }, data) {
    let subscription = null
    let plans = rootState.pricing.plans
    let user = firebase.auth().currentUser
    let payments = rootState.subscriptions.payments
    let subscriptions = rootState.subscriptions.subscriptions

    subscriptions = subscriptions.filter(s => s.status == 'active')
    payments = payments.filter(s => s.status == 'succeeded')

    if (subscriptions.length) {
      subscriptions = orderBy(subscriptions, 'created', 'desc')
      subscription = plans.find(p => p.id == subscriptions[0].metadata.plan)
    }
    else if (payments.length) {
      payments = orderBy(payments, 'created', 'desc')
      subscription = plans.find(p => p.id == payments[0].metadata.plan)
    }

    let newData = {
      creator: user.uid,
      plan: data.plan.id,
      snacks: data.goal.snacks ? data.goal.snacks : 0,
      recipes: data.meals.length,
      mainMeals: data.goal.mainMeals.length,
      dateDownloaded: firebase.firestore.Timestamp.now(),
      subscription: subscription ? subscription.title : null,
    }

    db.collection('mealDownloads')
      .add(newData)
      .then(() => {
        db.collection('mealPlans').doc(data.plan.id).update('downloads', firebase.firestore.FieldValue.increment(1))
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  // ADD A NEW FIELD TO THE PLAN
  async addHasDownloaded({ commit, dispatch }, { plan }) {

    await plan.ref.update({ hasDownloaded: true, updated: firebase.firestore.Timestamp.now() })
      .then(() => {
        commit('setHasDownloaded', plan.id)
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  async addNutritionInfo({ commit }, { plan, name, value }) {

    await plan.ref.update({ [name]: value })
      .then(() => {
        commit('updateNutritionInfo', { planId: plan.id, name, value })
      })
      .catch(error => console.log(error))
  },

  addMealToMarketplace({ commit }, mealId) {
    commit('addedToMarketplace', mealId)
  },

  removedFromMarketplace({ commit }, mealId) {
    commit('removeMarketplace', mealId)
  },

  async addGeneratedLink({ commit, dispatch, rootState }, { plan, details, silent }) {
    return new Promise((res, rej) => {
      const user = rootState.user.user
      function removeSpace(text) {
        return text.replace(/\s/g, "%20");
      }

      // const link = `${window.origin
      //   }/promoebookpage/${removeSpace(
      //     `${user.firstName}${user.lastName}`
      //   )}/${removeSpace(plan.name.replace(/#/gi, ""))}?i=${plan.id
      //   }`;

      const link = `https://mealguide.pro/promoebookpage/${removeSpace(
        `${user.firstName}${user.lastName}`
      )}/${removeSpace(plan.name.replace(/#/gi, ""))}?i=${plan.id
        }`;


      plan.ref.update({
        link,
        linkDetails: details,
        description: plan?.description,
        updatedAt: Date.now()
      })
        .then(() => {
          commit('setGenerateLinkDetails', { id: plan.id, link, details })

          if (!silent)
            dispatch('showSuccess', 'Success', { root: true })

          res()
        })
        .catch(e => {
          console.log(e)
          rej()
        })
    })
  },

  async updateRecipeLink({ dispatch }, { plan, details, silent }) {
    return new Promise((res, rej) => {
      plan.ref.update({
        linkDetails: details,
        updatedAt: Date.now()
      })
        .then(() => {

          if (!silent)
            dispatch('showSuccess', 'Success', { root: true })

          res()
        })
        .catch(e => {
          console.log(e)
          rej()
        })
    })
  },



  async addGeneratedMealplanLink({ commit, dispatch, rootState }, { plan, details, silent }) {
    return new Promise((res, rej) => {
      const user = rootState.user.user
      const link = `https://mealguide.pro/mealplanebook/${user.firstName}_${user.lastName}/${plan.name.replace(/[^\w\s]/ig, "")}?i=${plan.id}`
      // const link = `${window.origin}/mealplanebook/${user.firstName}_${user.lastName}/${plan.name}?i=${plan.id}`

      plan.ref.update({
        mealplanLink: link,
        linkDetails: details,
        mealplanDescription: plan?.mealplanDescription,
        updatedAt: Date.now()
      })
        .then(() => {
          commit('setGenerateMealplanLinkDetails', { id: plan.id, link, details })

          if (!silent)
            dispatch('showSuccess', 'Success', { root: true })

          res()
        })
        .catch(e => {
          console.log(e)
          rej()
        })
    })
  },


  /*------------------------------------------------------------------------------
   * GET PLAN FOR DOWNLOAD
   *----------------------------------------------------------------------------*/
  async getPlanToDownload({ dispatch }, id) {
    return new Promise((res, rej) => {
      db.collection('mealPlans')
        .doc(id).get()
        .then(doc => {
          if (doc.exists) {
            let data;
            try {
              data = Vue.prototype.$formatData(doc)
            }
            catch {
              data = doc
            }
            res(data)
          }
        })
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
          rej()
        })
    })
  },

  /*------------------------------------------------------------------------------
   * CREATE FOLDER 
   *----------------------------------------------------------------------------*/
  async addFolder({ dispatch, commit }, props) {
    commit('creatingFolderState', true)
    if (state.status.error) commit('setError', null)

    let data = Object.assign({}, props)
    data.user = user.uid
    data.createdAt = Date.now()
    data.mealplans = []
    data.recipes = []

    const res = await db.collection('folders')
      .add(data)
      .then((docRef) => {
        commit('creatingFolderState', false)
        dispatch('showSuccess', 'Client added.', { root: true })

        let newData = data
        newData.ref = docRef
        newData.id = docRef.id

        commit('insertFolder', newData)
        commit('selectFolder', newData)
        return { newData, error: false }
      })
      .catch(error => {
        commit('creatingFolderState', false)
        commit('setError', error.message)
        return { error: true }
      })

    return res
  },

  /*------------------------------------------------------------------------------
   * CREATE FOLDER FROM FORM
   *----------------------------------------------------------------------------*/
  async addFolderFromForm({ commit }, props) {
    commit('creatingFolderState', true)
    if (state.status.error) commit('setError', null)

    let data = Object.assign({}, props)
    data.user = props.user.id
    data.createdAt = Date.now()
    data.mealplans = []
    data.recipes = []

    const res = await db.collection('folders')
      .add(data)
      .then((docRef) => {
        commit('creatingFolderState', false)

        let newData = data
        newData.ref = docRef
        newData.id = docRef.id

        commit('insertFolder', newData)
        commit('selectFolder', newData)
        return { newData, error: false }
      })
      .catch(error => {
        commit('creatingFolderState', false)
        commit('setError', error.message)
        return { error: true }
      })

    return res
  },
  /*------------------------------------------------------------------------------
   * GET FOLDERS
   *----------------------------------------------------------------------------*/
  async getFolders({ commit, dispatch }) {
    commit('gettingState', true)
    let user = firebase.auth().currentUser

    await db.collection('folders')
      .where('user', '==', user.uid)
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        if (snapshot.size)
          commit('setFolders', snapshot)
        else {
          // commit('setCurrentFolderToOthers')
          commit('gettingState', false)
        }
      })
      .catch(error => {
        console.log(error.message)
        commit('gettingState', false)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * GET FOLDERS
   *----------------------------------------------------------------------------*/
  async getFoldersFromForm({ commit, dispatch }, user) {
    commit('gettingState', true)

    await db.collection('folders')
      .where('user', '==', user.id)
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        if (snapshot.size)
          commit('setFolders', snapshot)
        else {
          // commit('setCurrentFolderToOthers')
          commit('gettingState', false)
        }
      })
      .catch(error => {
        console.log(error.message)
        commit('gettingState', false)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * GET FOLDER BY ID
   *----------------------------------------------------------------------------*/
  async getFolder({ commit, dispatch }, id) {
    await db.collection('folders')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) commit('insertFolder', doc)
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * UPDATE FOLDER
   *----------------------------------------------------------------------------*/
  async updateFolder({ state, commit, dispatch }, props) {
    commit('creatingFolderState', true)

    if (state.status.error) commit('setError', null)
    let hasError = false


    let data = Object.assign({}, props)
    data.updatedAt = Date.now()

    await state.currentFolder.ref.update(omit(data, ['id', 'ref', 'user']))
      .then(() => {
        commit('creatingFolderState', false)
        commit('updateFolderName', { name: props.client, id: props.id })
        dispatch('showSuccess', 'Meal plan updated.', { root: true })
      })
      .catch(error => {
        commit('creatingFolderState', false)
        commit('setError', error.message)
        hasError = true
      })

    return hasError
  },

  async updateFolderFromForm({ state, commit }, props) {
    commit('creatingFolderState', true)

    if (state.status.error) commit('setError', null)
    let hasError = false


    let data = Object.assign({}, props)
    data.updatedAt = Date.now()
    delete data.mainMeals
    delete data.snacks

    await data.ref.update(omit(data, ['id', 'ref', 'user']))
      .then(() => {
        commit('creatingFolderState', false)
      })
      .catch(error => {
        commit('creatingFolderState', false)
        commit('setError', error.message)
        hasError = true
      })

    return hasError
  },

  async addPlanToFolder({ commit }, data) {

    await data.folder.ref.update({
      mealplans: firebase.firestore.FieldValue.arrayUnion(data.id),
      updatedAt: Date.now()
    })

    commit('addedPlanToFolder', data)
  },

  // FORM VERSION
  async addPlanToFolderForm(props, data) {
    try {
      await data.folder.ref.update({
        mealplans: firebase.firestore.FieldValue.arrayUnion(data.id),
        updatedAt: Date.now(),
      })
    } catch (error) {
      console.log(error)
    }
  },

  async moveToAnotherFolder({ state, commit, dispatch }, data) {
    commit('setMoveStatus', true)

    try {
      const date = Date.now()

      if (state.currentFolder.id != '1' && state.currentFolder.id != '2')
        await state.currentFolder.ref.update({
          mealplans: firebase.firestore.FieldValue.arrayRemove(data.mealplanId)
        })

      const folder = state.folders.find(f => f.id == data.folderId)

      await folder.ref.update({
        mealplans: firebase.firestore.FieldValue.arrayUnion(data.mealplanId),
        updatedAt: date
      })

      await data.plan.ref.update({
        folderId: data.folderId,
        updatedAt: date
      })
      commit('setMoveStatus', false)
      commit('moveSelectedMealplan', data)
    } catch (error) {
      commit('setMoveStatus', false)
      console.log(error)
      dispatch('showError', 'Something went wrong. Please try again', { root: true })
    }
  },

  async getAllMeals({ dispatch, commit }, plans) {

    if (plans.length > 1)
      commit('clearAllMeals')

    plans.forEach(async (plan) => {
      await plan.ref
        .collection('meals')
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            let meals = []
            snapshot.forEach(doc => {
              let data = Vue.prototype.$formatData(doc)
              meals.push(data.recipe)
            })

            if (plans.length) {
              let uniqMeals = ([...new Set(meals)])

              uniqMeals.forEach(m => commit('setAllMeals', m))
            }
          }
        })
        .catch(error => {
          dispatch('showError', error.message, { root: true })
        })
    })
  },

  async searchFolder({ dispatch }, { email, user }) {
    return new Promise((resolve, reject) => {
      db.collection('folders')
        .where('email', '==', email)
        .where('user', '==', user.id)
        .limit(1)
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            for (let doc of snapshot.docs) {
              resolve({ id: doc.id, ref: doc.ref, ...doc.data() })
            }
          }
          else
            resolve(false)
        })
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
          reject(error.message)
        })
    })
  },

  async searchFolderPromo({ dispatch }, email) {
    return new Promise((resolve, reject) => {
      let task = db.collection('folders')
        .where('email', '==', email)
        .where('promo', '==', true)
        .limit(1)

      task
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            for (let doc of snapshot.docs) {
              resolve({ id: doc.id, ref: doc.ref, ...doc.data() })
            }
          }
          else
            resolve(false)
        })
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
          reject(error.message)
        })
    })
  },

  /*------------------------------------------------------------------------------
   * PURCHASE REQUEST TOKEN
   *----------------------------------------------------------------------------*/
  async purchaseToken({ dispatch }, payload) {
    let currentUser = firebase.auth().currentUser

    let data = {
      price: payload.stampProduct.price.id,
      success_url: `${window.location.origin}/meal-plans?folder=${payload.folder}&mealplan=${payload.mealplan}&info=${payload.info}&request=success`,
      cancel_url: `${window.location.origin}/meal-plans?folder=${payload.folder}&mealplan=${payload.mealplan}&info=${payload.info}&request=failed`,
      allow_promotion_codes: true,
      metadata: {
        tokens: payload.stampProduct.stripe_metadata_token
      },
      mode: 'payment'
    }

    const docRef = await db
      .collection('customers')
      .doc(currentUser.uid)
      .collection('checkout_sessions')
      .add(data)

    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data()
      if (error) {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      }
      if (sessionId) {
        const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)
        stripe.redirectToCheckout({ sessionId })
      }
    })
  },

  async addCustomizationData({ commit, dispatch }, data) {
    commit('updateStatus', { updatingMeal: true })

    await data.meal.ref
      .update({
        customRecipes: firebase.firestore.FieldValue.arrayUnion(data.recipeId),
        customRecipesData: data.recipeData,
        updated: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        commit('updateField', data)
        if (!data.silent) dispatch('showSuccess', 'Successfully customized the recipe!', { root: true })
      })
      .catch(error => {
        console.log(error.message)
      })
      .finally(() => {
        commit('updateStatus', { updatingMeal: false })
      })
      .finally(() => {
        commit('updateStatus', { updatingMeal: false })
      })
  }

}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
