import Vue from 'vue'
import _ from 'lodash'
import router from '@/router'
import firebase from 'firebase'
import db from '@/firebase/init'
import moment from 'moment'
import { recipeIndex, myrecipeIndex } from '@/algolia/init'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const defaultPortions = () => {
  return [{
    name: 'gram',
    id: 'gram',
    unit: 'g',
    weight: 1
  }]
}

const defaultIngredient = () => {
  return {
    quantity: null,
    portion: {},
    food: {},
  }
}

const initialState = () => {
  return {
    ingredients: [],
    servingValues: [],
    foodPortions: defaultPortions(),
    newIngredient: defaultIngredient(),
    data: {
      methods: [],
      ingredients: [],
      numberOfServes: 1,
    },
    cell: {},
    meal: {},
    companies: [],
    duplicatePhoto: null,
    originalData: {
      methods: [],
      ingredients: [],
      mealTime: [],
      description: "",
      tags: [],
      nutritionTags: [],
      video: "",
      name: "",
      id: "",
      photo: ""
    },
    status: {
      error: null,
      added: false,
      adding: false,
      getting: false,
      updating: false,
      uploadProgress: 0,
      duplicating: false,
      deletingPhoto: false,
      uploadingPhoto: false,
      gettingPortions: false,
      deletingIngredient: [],
      addingIngredient: false,
    }
  }
}

const state = initialState()

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  // WEIGHT
  getTotalWeight: (state) => {
    if (state.ingredients && state.ingredients.length) {
      return Math.round(_.sumBy(state.ingredients, (ing) => { return ing.portion.weight * ing.quantity }) / state.data.numberOfServes)
    }
    else {
      return 0
    }
  },

  // ENERGY
  getTotalEnergy: (state) => {
    if (state.ingredients && state.ingredients.length) {
      return _.sumBy(state.ingredients, (ing) => { 
        return parseFloat((ing.food.energyKj && ((ing.food.energyKj.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
    }
    else {
      return 0
    }
  },
  
  // ENERGY
  getTotalProtein: (state) => {
    if (state.ingredients && state.ingredients.length) {
      return _.sumBy(state.ingredients, (ing) => { 
        return parseFloat((ing.food.protein && ((ing.food.protein.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
    }
    else {
      return 0
    }
  },
  
  // FAT
  getTotalFat: (state) => {
    if (state.ingredients && state.ingredients.length) {
      return _.sumBy(state.ingredients, (ing) => { 
        return parseFloat((ing.food.fat && ((ing.food.fat.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
    }
    else {
      return 0
    }
  },
  
  // CARBOHYDRATE
  getTotalCarbohydrate: (state) => {
    if (state.ingredients && state.ingredients.length) {
      return _.sumBy(state.ingredients, (ing) => { 
        return parseFloat((ing.food.carbohydrate && ((ing.food.carbohydrate.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
    }
    else {
      return 0
    }
  },
  
  // SUGAR
  getTotalSugar: (state) => {
    if (state.ingredients && state.ingredients.length) {
      return _.sumBy(state.ingredients, (ing) => { 
        return parseFloat((ing.food.sugars && ((ing.food.sugars.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
    }
    else {
      return 0
    }
  },
  
  // FIBRE
  getTotalFibre: (state) => {
    if (state.ingredients && state.ingredients.length) {
      return _.sumBy(state.ingredients, (ing) => { 
        return parseFloat((ing.food.fibre && ((ing.food.fibre.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
    }
    else {
      return 0
    }
  },
  
  // SODIUM
  getTotalSodium: (state) => {
    if (state.ingredients && state.ingredients.length) {
      return _.sumBy(state.ingredients, (ing) => { 
        return parseFloat((ing.food.sodium && ((ing.food.sodium.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
    }
    else {
      return 0
    }
  },
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

 const mutations = {
   addingState(state, bol) {
     state.status.adding = bol
   },

   resetState(state) {
     Object.assign(state, initialState())
     state.ingredients = []
   },

   resetOriginalRecipe(state) {
    state.originalRecipe = {
      methods: [],
      ingredients:[]
    }
   },

   insertNewIngredient(state) {
     state.data.ingredients.push(state.newIngredient)
     state.newIngredient = {}
   },

   removeIngredient(state, ingredient) {
     state.ingredients.splice(state.ingredients.indexOf(ingredient), 1)
   },

   setAddedState(state, bol) {
     state.status.added = bol
   },

   setError(state, message) {
     state.status.error = message
   },

   setData(state, payload) {
     state.data = payload
   },

   setCell(state, payload) {
     state.cell = payload
   },

   setMeal(state, payload) {
    state.meal = payload
   },

   setDuplicatePhoto(state, payload) {
    state.duplicatePhoto = payload
   },

   setOriginalData(state, payload) {
    state.originalData.ingredients = payload.ingredients
    state.originalData.mealTime = [...payload.mealTime]
    state.originalData.methods = [...payload.methods]
    state.originalData.description = payload?.description || "",
    state.originalData.tags = [...payload.tags],
    state.originalData.nutritionTags = [...payload.nutritionTags],
    state.originalData.video = payload?.video || "",
    state.originalData.name = payload.name
    state.originalData.id = payload.id
    state.originalData.photo = payload.photo

   },

   clearOriginalData(state) {
    state.originalData = {
      methods: [],
      ingredients: [],
      mealTime: [],
      description: "",
      tags: [],
      nutritionTags: [],
      video: "",
      name: "",
      id: "",
      photo: ""
    }
   },

   setUploadingPhoto(state, bol) {
     state.status.uploadingPhoto = bol
   },

   setUploadProgress(state, value) {
     state.status.uploadProgress = value
   },

   setDeletingPhotoState(state, bol) {
     state.status.deletingPhoto = bol
   },

   setPhotoData(state, val) {
     state.data.photo = val
   },

   setNewIngredientName(state, name) {
    state.newIngredient.name = name
  },
  
  setNewIngredientPortion(state, portion) {
    state.newIngredient.portion = portion
  },

  addingIngredientState(state, bol) {
    state.status.addingIngredient = bol
  },

  insertIngredient(state) {
    let newIngredient = Object.assign({}, state.newIngredient)
    if (!state.ingredients.find(ing => ing.food.id == newIngredient.food.id)) state.ingredients.push(newIngredient)
    Object.assign(state.newIngredient, defaultIngredient())
    state.foodPortions = defaultPortions()
    state.status.addingIngredient = false
  },

  resetIngredients(state) {
    state.ingredients = []
  },

  addIngredient(state, payload) {
    let ingredient = state.ingredients.find(ing => ing.food.id == payload.food.id)

    if (ingredient) {
      Vue.set(state.ingredients, state.ingredients.indexOf(ingredient), payload)
    }
    else {
      state.ingredients.push(payload)
    }
  },

  setAddingState(state, bol) {
    state.status.adding = bol
  },

  insertMethod(state, method) {
    if (!state.data.methods) state.data.methods = []
    state.data.methods.push(method)
  },

  removeMethod(state, i) {
    state.data.methods.splice(i, 1)
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  insertNutritionTag(state, id) {
    if (!state.data.nutritionTags) state.data.nutritionTags = []
    if (!state.data.nutritionTags.find(tag => tag == id)) state.data.nutritionTags.push(id)
  },
  
  removeNutritionTag(state, id) {
    if (state.data.nutritionTags) {
      let tag = state.data.nutritionTags.find(tag => tag == id)
      if (tag) 
        state.data.nutritionTags.splice(state.data.nutritionTags.indexOf(tag), 1)
    }
  },

  insertMealTag(state, id) {
    if (!state.data.tags) state.data.tags = []
    if (!state.data.tags.find(tag => tag == id)) state.data.tags.push(id)
  },

  removeMealTag(state, id) {
    if (state.data.tags) {
      let tag = state.data.tags.find(tag => tag == id)
      if (tag) 
        state.data.tags.splice(state.data.tags.indexOf(tag), 1)
    }
  },

  resetNutritionTag(state) {
    state.data.nutritionTags = []
  },

  setPortions(state, payload) {
    state.foodPortions = defaultPortions()

    if (payload.length) {
      payload.forEach(portion => {
        state.foodPortions.push(portion)
      })
    }

    state.status.gettingPortions = false
  },

  setFood(state, payload) {
    state.newIngredient.food = payload.food
    state.newIngredient.portions = payload.portions
  },
  
  setPortion(state, payload) {
    state.newIngredient.portion = payload
  },

  resetPortions(state) {
    state.foodPortions = defaultPortions()
    state.status.gettingPortions = false
  },

  gettingPortionsState(state, bol) {
    state.status.gettingPortions = bol
  },

  deletingIngredientState(state, id) {
    state.status.deletingIngredient.push(id)
  },
  
  removeDeletingIngredientState(state, id) {
    state.status.deletingIngredient.splice(state.status.deletingIngredient.indexOf(id), 1)
  },

  updateIngredientOrder(state, payload) {
    payload.forEach((ing, i) => {
      let ingredient = state.ingredients.find(ingr => ingr.food.id == ing.food.id)
      let newdata = ing
      newdata.order = i
      Vue.set(state.ingredients, state.ingredients.indexOf(ingredient), ing)
    })
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  resetTags(state) {
    state.data.nutritionTags = []
  },

  updateRecipeField(state, payload) {
    Vue.set(payload.recipe, payload.field, payload.value)
  },

  updateNutritionValues(state, payload) {
    state.servingValues = payload.serving
    state.servingValuesPerHundredGram = payload.hundredGram
    console.log(payload)
  },

  resetDataPhotos(state) {
    state.data.OriginalPhotoUrl = null
    state.data.largePhotoUrl = null
    state.data.mediumPhotoUrl = null
    state.data.thumbPhotoUrl = null
    state.data.photo = null
  },

  // COMPANIES FEATURES
  setCompanies(state, payload) {
      state.companies = []

      if (payload.size) {
        payload.forEach(doc => {
          if (!state.companies.find(t => t.id == doc.id)) {
            let data = doc.data()
            data.id = doc.id
            data.ref = doc.ref
            state.companies.push(data)
          }
        })
      }
  },
  insertCompany(state, payload) {
    if (!state.companies.find(t => t.id == payload.id)) {
      state.companies.push(payload)
    }
  },
 }

 /*------------------------------------------------------------------------------
  * ACTIONS
  *----------------------------------------------------------------------------*/

const actions = {

  /*------------------------------------------------------------------------------
   * ADD RECIPE
   *----------------------------------------------------------------------------*/
  async addRecipe({ commit, state, dispatch, rootState }, isPublish = false) {
    commit('addingState', true)
    if (state.status.error) commit('setError', null)

    let user = firebase.auth().currentUser
    let data = state.data
    const { role, firstName } = rootState.user.user
    data.status = 'published'
    data.servingValues = state.servingValues
    data.servingValuesPerHundredGram = state.servingValuesPerHundredGram
    data.ingredients = state.ingredients.map((ing, index) => {
      return {
        food: ing.food.name,
        portion: ing.portion.name,
        quantity: ing.quantity,
        order: typeof ing.order === 'undefined' ? index : ing.order
      }
    })

    if (!state.ingredients.length) {
      commit('setError', 'Please add at least one ingredient.')
      commit('addingState', false)
    }

    if (!state.status.error) {
      if (!data.user) data.user = user.uid
      let createdAt = firebase.firestore.Timestamp.now()
      let updatedAt = firebase.firestore.Timestamp.now()

      data.createdAt = moment(createdAt.toDate()).format('')
      data.updatedAt = moment(updatedAt.toDate()).format('')

      data.status = isPublish ? 'published' : 'pending';

      var recipeRef = data.id ? db.collection('recipes').doc(data.id) : db.collection('recipes').doc()
      recipeRef.set(_.omit(data, ['id', 'ref', 'photo']), { merge: true })

      let batch = db.batch()

      // SAVE INGREDIENTS
      state.ingredients.forEach((ing, index) => {
        let ingredient = {
          food: ing.food.id,
          portion: ing.portion.id || 'gram',
          quantity: ing.quantity,
          order: typeof ing.order === 'undefined' ? index : ing.order
        }

        let ingRef = recipeRef.collection('ingredients').doc(ing.food.id)
        batch.set(ingRef, ingredient)
      })

      await batch.commit()
      .then(() => { 
        // SAVE INGREDIENTS
        // IF PHOTO INCLUDED
        if (typeof(data.photo) == 'object') {
          var storageRef = firebase.storage().ref()
          var name = `${Date.now()}_${state.data.photo.name}`

          var metadata = {
            contentType: state.data.photo.type
          }

          var uploadTask  = storageRef.child(`recipes/${name}`).put(state.data.photo, metadata)

          uploadTask.on('state_changed', snapshot => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            commit('setUploadProgress', progress)
          }, error => {
            commit('setError', error.message)
          }, () => {
            recipeRef.update({ photo: name })
            .then(() => {
              if (!state.data.id) {
                commit('setAddedState', true)
                // Send an email if he/she is a Dietitian/Nutritionist/Naturopath
                if(role === 'dietitian' ||  role === 'nutritionist' || role === 'naturopath') {
                  let emailData = {
                    recipient: user.email,
                    subject: 'Recipe submitted for review',
                    message: `Your recipe "<strong>${state.data.name}</strong>" has been submitted for review. We'll notify you once approved.`
                  }

                  dispatch('mail/notify', emailData, { root: true })
        
                  // Email to Chris
                  dispatch('mail/added', {firstName , mealType: 'Recipe', mealName: state.data.name}, { root: true })

                }

                if(recipeRef.id)
                  dispatch('recipes/getRecipe', recipeRef.id, { root: true })
                
              }
              else {
                dispatch('showSuccess', 'Recipe was successfully updated.', { root: true })
                commit('addingState', false)
                commit('setPhotoData', name)
                commit('setUploadProgress', 0)

                if(recipeRef.id)
                  dispatch('recipes/getRecipe', recipeRef.id, { root: true })
              }
            })
          })

        } 
        else if (!state.data.id) {
          commit('setAddedState', true)
          // Send an email if he/she is a Dietitian/Nutritionist/Naturopath
          if(role === 'dietitian' || role === 'nutritionist' || role === 'naturopath') {
            let emailData = {
              recipient: user.email,
              subject: 'Recipe submitted for review',
              message: `Your recipe "<strong>${state.data.name}</strong>" has been submitted for review. We'll notify you once approved.`
            }

            dispatch('mail/notify', emailData, { root: true })

            // Email to Chris
            dispatch('mail/added', {firstName , mealType: 'Recipe', mealName: state.data.name}, { root: true })

          }

          dispatch('recipes/getRecipe', recipeRef.id, { root: true })
        }
        else {
          dispatch('showSuccess', 'Recipe was successfully updated.', { root: true })
          dispatch('recipes/getRecipe', recipeRef.id, { root: true })
          commit('addingState', false)
        }

        // ADD TO ALGOLIA
        let objectData = {
          name: data.name,
          objectID: recipeRef.id
        }

        recipeIndex.saveObject(objectData)
      })
    }

  },

  /*------------------------------------------------------------------------------
   * ADD TO MY RECIPE
   *----------------------------------------------------------------------------*/
  async addMyRecipe({ commit, state, dispatch }) {
    commit('addingState', true)
    if (state.status.error) commit('setError', null)

    let user = firebase.auth().currentUser
    let data = state.data
    data.status = 'published'
    data.servingValues = state.servingValues
    data.servingValuesPerHundredGram = state.servingValuesPerHundredGram
    data.ingredients = state.ingredients.map((ing, index) => {
      return {
        food: ing.food.name,
        portion: ing.portion.name,
        quantity: ing.quantity,
        order: typeof ing.order === 'undefined' ? index : ing.order
      }
    })

    if (!state.ingredients.length) {
      commit('setError', 'Please add at least one ingredient.')
      commit('addingState', false)
    }

    if (!state.status.error) {
      if (!data.user) data.user = user.uid
      let createdAt = firebase.firestore.Timestamp.now()
      let updatedAt = firebase.firestore.Timestamp.now()

      data.createdAt = moment(createdAt.toDate()).format('')
      data.updatedAt = moment(updatedAt.toDate()).format('')

      data.status = 'published';

      var recipeRef = data.id ? db.collection('my_recipes').doc(data.id) : db.collection('my_recipes').doc()
      recipeRef.set(_.omit(data, ['id', 'ref', 'photo']), { merge: true })

      let batch = db.batch()

      // SAVE INGREDIENTS
      state.ingredients.forEach((ing, index) => {
        let ingredient = {
          food: ing.food.id,
          portion: ing.portion.id || 'gram',
          quantity: ing.quantity,
          order: typeof ing.order === 'undefined' ? index : ing.order
        }

        let ingRef = recipeRef.collection('ingredients').doc(ing.food.id)
        batch.set(ingRef, ingredient)
      })

      await batch.commit()
      .then(() => { 
        // SAVE INGREDIENTS
        // IF PHOTO INCLUDED
        if (typeof(data.photo) == 'object') {
          var storageRef = firebase.storage().ref()
          var name = `${Date.now()}_${state.data.photo.name}`

          var metadata = {
            contentType: state.data.photo.type
          }

          var uploadTask  = storageRef.child(`recipes/${name}`).put(state.data.photo, metadata)

          uploadTask.on('state_changed', snapshot => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            commit('setUploadProgress', progress)
          }, error => {
            commit('setError', error.message)
          }, () => {
            recipeRef.update({ photo: name })
            .then(() => {
              if (!state.data.id) {
                commit('setAddedState', true)
                if(recipeRef.id)
                  dispatch('recipes/getMyRecipe', recipeRef.id, { root: true })
              }
              else {
                dispatch('showSuccess', 'Recipe was successfully updated.', { root: true })
                commit('addingState', false)
                commit('setPhotoData', name)
                commit('setUploadProgress', 0)

                if(recipeRef.id)
                  dispatch('recipes/getMyRecipe', recipeRef.id, { root: true })
              }
            })
          })

        } 
        else if (!state.data.id) {
          commit('setAddedState', true)
          dispatch('recipes/getMyRecipe', recipeRef.id, { root: true })
        }
        else {
          dispatch('showSuccess', 'Recipe was successfully updated.', { root: true })
          dispatch('recipes/getMyRecipe', recipeRef.id, { root: true })
          commit('addingState', false)
        }

        // ADD TO ALGOLIA
        let objectData = {
          name: data.name,
          objectID: recipeRef.id,
          user: user.uid
        }

        myrecipeIndex.saveObject(objectData)
      })
    }

  },

  /*------------------------------------------------------------------------------
   * CUSTOMIZE RECIPE
   *----------------------------------------------------------------------------*/
  async addCustomizeRecipe({ commit, state, dispatch, rootState }) {
    commit('addingState', true)
    if (state.status.error) commit('setError', null)

    let user = firebase.auth().currentUser
    let data = state.data
    const { role, firstName, lastName, email } = rootState.user.user
    data.servingValues = state.servingValues
    data.servingValuesPerHundredGram = state.servingValuesPerHundredGram
    data.ingredients = state.ingredients.map((ing, index) => {
      return {
        food: ing.food.name,
        portion: ing.portion.name,
        quantity: ing.quantity,
        order: typeof ing.order === 'undefined' ? index : ing.order
      }
    })

    /**
     * GET ALL THE DIFFERENCE
     */

    // INGREDIENTS
    let ing = state.ingredients.map(ing => ing.food.name)
    let oldIng = state.originalData.ingredients.map(ing => ing.food)

    let newIng = ing.filter(val => !oldIng.includes(val))
    let removedIng = oldIng.filter(val => !ing.includes(val))

    // MEAL TIMES
    let newTimes = data.mealTime.filter(t => !state.originalData.mealTime.includes(t))
    let removedTimes = state.originalData.mealTime.filter(t => !data.mealTime.includes(t))

    // METHODS
    let newMethods = data.methods.filter(m => !state.originalData.methods.includes(m))
    let removedMethods = state.originalData.methods.filter(m => !data.methods.includes(m))

    // TAGS
    let newTags = data.tags.filter(m => !state.originalData.tags.includes(m))
    let removedTags = state.originalData.tags.filter(m => !data.tags.includes(m))

    // NUTRITION TAGS
    let newNutriTags = data.nutritionTags.filter(n => !state.originalData.nutritionTags.includes(n))

    let removedNutriTags = state.originalData.nutritionTags.filter(n => !data.nutritionTags.includes(n))

    // DESCRIPTION
    let newDesc
    if(data.description)
      newDesc = data.description !== state.originalData.description ? data.description : ""
    else
      newDesc = ""

    // NAME
    let newName
    if(data.name)
      newName = data.name  != state.originalData.name ? data.name : ""
    else
      newName = ""

    // VIDEO
    let newVideo
    if(data.video)
      newVideo = data.video !== state.originalData.video ? data.video : ""
    else
      newVideo = ""

    // END

    if (!state.ingredients.length) {
      commit('setError', 'Please add at least one ingredient.')
      commit('addingState', false)
    }

    if (!state.status.error) {
      data.customized_by_id = user.uid
      data.customized_by_name = `${firstName} ${lastName}`
      data.customized_by_role = role
      data.customized_by_email = email
      data.isCustomized = true
      data.oldId = state.originalData.id
      data.newData = {
        newIng,
        newTimes,
        newMethods,
        newTags,
        newNutriTags,
        newDesc,
        newName,
        newVideo
      }
      data.removedData = {
        removedIng,
        removedTimes,
        removedMethods,
        removedTags,
        removedNutriTags,
      }

      if(state.duplicatePhoto) 
        data.photo = state.duplicatePhoto

      let createdAt = firebase.firestore.Timestamp.now()
      let updatedAt = firebase.firestore.Timestamp.now()

      data.createdAt = moment(createdAt.toDate()).format('')
      data.updatedAt = moment(updatedAt.toDate()).format('')

      data.status = data.status === 'pending' ? data.status : 'requested';

      var recipeRef = data.id ? db.collection('recipes').doc(data.id) : db.collection('recipes').doc()
      recipeRef.set(_.omit(data, ['id', 'ref', 'photo']), { merge: true })

      let batch = db.batch()

      // SAVE INGREDIENTS
      state.ingredients.forEach((ing, index) => {
        let ingredient = {
          food: ing.food.id,
          portion: ing.portion.id || 'gram',
          quantity: ing.quantity,
          order: typeof ing.order === 'undefined' ? index : ing.order
        }

        let ingRef = recipeRef.collection('ingredients').doc(ing.food.id)
        batch.set(ingRef, ingredient)
      })

      await batch.commit()
      .then(() => { 
        // SAVE INGREDIENTS
        // IF PHOTO INCLUDED
        if (typeof(data.photo) == 'object') {
          var storageRef = firebase.storage().ref()
          var name = `${Date.now()}_${state.data.photo.name}`

          var metadata = {
            contentType: state.data.photo.type
          }

          var uploadTask  = storageRef.child(`recipes/${name}`).put(state.data.photo, metadata)

          uploadTask.on('state_changed', snapshot => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            commit('setUploadProgress', progress)
          }, error => {
            commit('setError', error.message)
          }, () => {
            recipeRef.update({ photo: name })
            .then(() => {
                commit('setAddedState', true)

                db.collection('users').doc(data.user)
                .get()
                .then(doc => {
                  if(doc.exists) {
                    let user = Vue.prototype.$formatData(doc)

                    // Send an email if he/she is a Dietitian
                    let emailData = {
                      recipient: user.email,
                      subject: 'Recipe customization request',
                      message: `Hi ${user.firstName}, <br/> <strong>${firstName} ${lastName} </strong> has requested an edit for the recipe "<strong>${state.originalData.name}</strong>". You can view it at the recipes page under the requested tab <br/><br/>
                      ${newName ? `New name: ${newName}<br/>` : ""}
                      ${newDesc ? `New description: ${newDesc}<br/>` : ""}
                      ${newVideo ? `New video url: ${newVideo}<br/>`: ""}
                      ${newIng.length ? `New Ingredients:<br/><ul>${newIng.map(ing => `<li>${ing}</li>`).join('')}</ul><br>`: ""}
                      ${removedIng.length ? `Removed Ingredients:<br/><ul>${removedIng.map(ing => `<li>${ing}</li>`).join('')}</ul><br/>` : ""}
                      ${newTimes.length ? `New Meal Times:<br/><ul>${newTimes.map(time => `<li>${time}</li><br/>`).join('')}</ul><br/>` : ""}
                      ${removedTimes.length ? `Removed Meal Times: <br/><ul>${removedTimes.map(time => `<li>${time}</li>`).join('')}</ul><br/>` : ""}
                      ${newMethods.length ? `New Methods: <br/><ul>${newMethods.map(m => `<li>${m}</li>`).join('')}</ul><br/>` : ""}
                      ${removedMethods.length ? `Removed Methods: <br/><ul>${removedMethods.map(m => `<li>${m}</li>`).join('')}</ul><br/>`: ""}
                      `
                    }

                    dispatch('mail/notify', emailData, { root: true })
                  }
                })

                if(recipeRef.id)
                  dispatch('recipes/getRecipe', recipeRef.id, { root: true })
                
            })
          })

        } 
        else {
          commit('setAddedState', true)

          db.collection('users').doc(data.user)
          .get()
          .then(doc => {
            if(doc.exists) {
              let user = Vue.prototype.$formatData(doc)

              // Send an email if he/she is a Dietitian
              let emailData = {
                recipient: user.email,
                subject: 'Recipe customization request',
                message: `Hi ${user.firstName}, <br/> <strong>${firstName} ${lastName} </strong> has requested an edit for the recipe "<strong>${state.originalData.name}</strong>". You can view it at the recipes page under the requested tab <br/><br/>
                ${newName ? `New name: ${newName}<br/>` : ""}
                ${newDesc ? `New description: ${newDesc}<br/>` : ""}
                ${newVideo ? `New video url: ${newVideo}<br/>`: ""}
                ${newIng.length ? `New Ingredients:<br/><ul>${newIng.map(ing => `<li>${ing}</li>`).join('')}</ul><br>`: ""}
                ${removedIng.length ? `Removed Ingredients:<br/><ul>${removedIng.map(ing => `<li>${ing}</li>`).join('')}</ul><br/>` : ""}
                ${newTimes.length ? `New Meal Times:<br/><ul>${newTimes.map(time => `<li>${time}</li><br/>`).join('')}</ul><br/>` : ""}
                ${removedTimes.length ? `Removed Meal Times: <br/><ul>${removedTimes.map(time => `<li>${time}</li>`).join('')}</ul><br/>` : ""}
                ${newMethods.length ? `New Methods: <br/><ul>${newMethods.map(m => `<li>${m}</li>`).join('')}</ul><br/>` : ""}
                ${removedMethods.length ? `Removed Methods: <br/><ul>${removedMethods.map(m => `<li>${m}</li>`).join('')}</ul><br/>`: ""}
                `
              }

              dispatch('mail/notify', emailData, { root: true })
    
            }
          })

          if(recipeRef.id)
            dispatch('recipes/getRecipe', recipeRef.id, { root: true })
        }

        // ADD TO ALGOLIA
        let objectData = {
          name: data.name,
          objectID: recipeRef.id
        }


        if(state.cell) {
          let insertData = {
            cell: state.cell,
            recipe: { id: recipeRef.id, ...data }
          }


          if(state.meal) {
            Promise.all([dispatch('mealplans/delete', state.meal, { root: true })])
            .then(() => {

              dispatch('recipes/removeValueAction', { meal: state.cell.meal, day: state.cell.day})

              dispatch('mealplans/insertMeal', insertData, { root: true })
            })
          }
          // Else, just add it
          else {
            dispatch('mealplans/insertMeal', insertData, { root: true })
          }

        }


        recipeIndex.saveObject(objectData)
      })
    }

  },


  /*------------------------------------------------------------------------------
   * DELETE PHOTO
   *----------------------------------------------------------------------------*/
  async deletePhoto({ state, commit, dispatch }) {
    commit('setDeletingPhotoState', true)

    var storageRef = firebase.storage().ref()

    await storageRef.child(`recipes/${state.data.photo}`).delete()
    .then(() => {
      try {
        storageRef.child(`recipes/thumb_${state.data.photo}`).delete().catch(error => console.log(error.message))
        storageRef.child(`recipes/medium_${state.data.photo}`).delete().catch(error => console.log(error.message))
        storageRef.child(`recipes/large_${state.data.photo}`).delete().catch(error => console.log(error.message))
      }
      catch(e) {
        console.log(e)
      }

      state.data.ref.update({ 
        photo: null,
        thumbPhotoUrl: null,
        largePhotoUrl: null,
        originalPhoto: null,
        mediumPhotoUrl: null,
        OriginalPhotoUrl: null,
      })
      .then(() => {
        commit('setDeletingPhotoState', false)
        commit('setPhotoData', null)
        commit('resetDataPhotos')
      })

    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      commit('setDeletingPhotoState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET RECIPE
   *----------------------------------------------------------------------------*/
  getRecipe({ commit, dispatch }, id) {
    return new Promise((res, rej) => {
      commit('gettingState', true)
      db.collection('recipes')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          data.methods = data.methods || []
          data.isPrivate = false
          commit('setData', data)

          commit('gettingState', false)
          dispatch('getRecipeIngredients', data)
          .then(() => {
            res(data)
          }).catch(error => {
            console.log(error.message)
            dispatch('showError', error.message, { root: true })
            commit('gettingState', false)
            rej()
          })
        }
        else {
          db.collection('my_recipes')
          .doc(id).get()
          .then(doc => {
            if (doc.exists) {
              let data = doc.data()
              data.id = doc.id
              data.ref = doc.ref
              data.methods = data.methods || []
              data.isPrivate = true
              commit('setData', data)

              commit('gettingState', false)
              dispatch('getRecipeIngredients', data)
              .then(() => {
                res(data)
              }).catch(error => {
                console.log(error.message)
                dispatch('showError', error.message, { root: true })
                commit('gettingState', false)
                rej()
              })
            }
            else {
              commit('resetState')
              commit('gettingState', false)
              res()
            }
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        commit('gettingState', false)
        rej()
      })
    })
  },

  /*------------------------------------------------------------------------------
   * GET MY RECIPE
   *----------------------------------------------------------------------------*/
  getMyRecipe({ commit, dispatch }, id) {
    return new Promise((res, rej) => {
      commit('gettingState', true)
      db.collection('my_recipes')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          data.methods = data.methods || []
          commit('setData', data)
          commit('gettingState', false)
          dispatch('getRecipeIngredients', data)
          .then(() => {
            res()
          }).catch(error => {
            console.log(error.message)
            dispatch('showError', error.message, { root: true })
            commit('gettingState', false)
            rej()
          })
        }
        else {
          commit('resetState')
          commit('gettingState', false)
          res()
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        commit('gettingState', false)
        rej()
      })
    })
  },


  /*------------------------------------------------------------------------------
   * GET RECIPE INGREDIENTS
   *----------------------------------------------------------------------------*/
  getRecipeIngredients({ commit }, recipe) {
    commit('resetIngredients')

    return new Promise((res, rej) => {
      
      recipe.ref
      .collection('ingredients')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          new Promise ((res) => {
            let counter = 0
            snapshot.docs.forEach((doc, index) => {
              let ingredient = doc.data()
              let data = {
                quantity: ingredient.quantity,
                order: typeof ingredient.order !== undefined ? ingredient.order : index
              }

              db.collection('foods')
              .doc(ingredient.food).get()
              .then(foodDoc => {
                if (foodDoc.exists) {
                  let food = foodDoc.data()
                  food.id = foodDoc.id
                  food.ref = foodDoc.ref
                  data.food = food

                  food.ref.collection('portions')
                  .get()
                  .then(portionsSnap => {
                    if (portionsSnap.size) {
                      let portions = portionsSnap.docs.map(pDoc => {
                        let portionData = pDoc.data()
                        portionData.id = pDoc.id
                        portionData.ref = pDoc.id
                        return portionData
                      })

                      portions.push({
                        id: 'gram',
                        name: 'gram',
                        unit: 'g',
                        weight: 1
                      })

                      data.portions =  portions
                      data.portion = portions.find(p => p.id == ingredient.portion)
                      data.portion = data.portion || {id: 'gram', name: 'gram', unit: 'g', weight: 1}
                    }
                    else {
                      data.portions =  [{
                        id: 'gram',
                        name: 'gram',
                        unit: 'g',
                        weight: 1
                      }]

                      data.portion = {
                        id: 'gram',
                        name: 'gram',
                        unit: 'g',
                        weight: 1
                      }
                    }

                    commit('addIngredient', data)

                    counter++

                    if(counter === snapshot.size) 
                      res()
                  })
                  .then(() => {
                    // res()
                  })
                  .catch((error) => {
                    console.log(error.message)
                    rej()
                  })
                }
                else {
                  counter++
                }
              })
              .catch((error) => {
                console.log(error.message)
                rej()
              })
            })
          }).then(() => {
            res()
          }).catch(error => {
            console.log(error.message)
            rej()
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        rej()
      })
    })
  },

  /*------------------------------------------------------------------------------
   * GET FOOD
   *----------------------------------------------------------------------------*/
  getFood({ commit, dispatch }, food) {
    let id = food.objectID || food.id.toString()
    
    db.collection('foods')
    .doc(id).get()
    .then(doc => {
      if (doc.exists) {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        var portions = {
          id: 'gram',
          name: 'gram',
          unit: 'g',
          weight: 1
        }

        food.ref.collection('portions')
        .get()
        .then(portionsSnap => {
          if (portionsSnap.size) {
            portions = portionsSnap.docs.map(pDoc => {
              let portionData = pDoc.data()
              portionData.id = pDoc.id
              portionData.ref = pDoc.id
              return portionData
            })

            portions.push({
              id: 'gram',
              name: 'gram',
              unit: 'g',
              weight: 1
            })
          }

          commit('setFood', { food: data, portions })
          dispatch('getPortions', food.id)
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * SEARCH INGREDIENT
   *----------------------------------------------------------------------------*/
  getPortions({ commit }, id) {
    commit('gettingPortionsState', true)

    db.collection('foods').doc(id)
    .collection('portions').get()
    .then(snapshot => {
      if (snapshot.size) {
        let portions = []

        snapshot.forEach(doc => {
          let portion = doc.data()
          portion.id = doc.id
          portion.ref = doc.ref
          portions.push(portion)
        })

        commit('setPortions', portions)
      }
      // GET PORTIONS FROM API IF NO PORTIONS FOUND FROM FIRESTORE
      else {
        var searchIng = firebase.functions().httpsCallable('apis-searchIngredient')
        let portions = []
        let portionsData = []
        let batch = db.batch()

        searchIng({ food: id })
        .then(resp => {
          let response = resp.data
          
          Object.keys(response).forEach(key => {
            if (typeof response[key] == 'object') {
              let ptns = response[key].portions
              if (ptns && ptns.length !== 0) {
                Object.keys(ptns).forEach(k => {
                  if (ptns[k]['name']) {
                    if (!portions.find(port => port.name == ptns[k]['name'])) portions.push({ name: ptns[k]['name'], unit: ptns[k]['unit'], weight: ptns[k]['val'] })
                  }
                })
              }
            }
          })

          if (portions.length) {
            portions.forEach(portion => {
              let docRef = db.collection('foods').doc(id).collection('portions').doc()
              batch.set(docRef, portion)
              portion.ref = docRef
              portion.id = docRef.id
              portionsData.push(portion)
            })

            batch.commit()
            .then(() => {
              commit('setPortions', portionsData)
            })
          }
          else {
            commit('resetPortions')
          }
        })
        .catch(error => {
          console.log(error.message)
        })        
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * SEARCH INGREDIENT ASYNC
   *----------------------------------------------------------------------------*/

  getPortionsAsync({ commit }, id) {
    // commit('gettingPortionsState', true)

    return new Promise((res, rej) => {
      db.collection('foods').doc(id)
      .collection('portions').get()
      .then(snapshot => {
        if (snapshot.size) {
          let portions = []

          snapshot.forEach(doc => {
            let portion = doc.data()
            portion.id = doc.id
            portion.ref = doc.ref
            portions.push(portion)
          })

          res(portions)
        }
        // GET PORTIONS FROM API IF NO PORTIONS FOUND FROM FIRESTORE
        else {
          var searchIng = firebase.functions().httpsCallable('apis-searchIngredient')
          let portions = []
          let portionsData = []
          let batch = db.batch()

          searchIng({ food: id })
          .then(resp => {
            let response = resp.data
            
            Object.keys(response).forEach(key => {
              if (typeof response[key] == 'object') {
                let ptns = response[key].portions
                if (ptns && ptns.length !== 0) {
                  Object.keys(ptns).forEach(k => {
                    if (ptns[k]['name']) {
                      if (!portions.find(port => port.name == ptns[k]['name'])) portions.push({ name: ptns[k]['name'], unit: ptns[k]['unit'], weight: ptns[k]['val'] })
                    }
                  })
                }
              }
            })

            if (portions.length) {
              portions.forEach(portion => {
                let docRef = db.collection('foods').doc(id).collection('portions').doc()
                batch.set(docRef, portion)
                portion.ref = docRef
                portion.id = docRef.id
                portionsData.push(portion)
              })

              batch.commit()
              .then(() => {
                // commit('setPortions', portionsData)
                res(portionsData)
              })
            }
            else {
              res([])
              commit('resetPortions')
            }
          })
          .catch(error => {
            rej(error.message)
          })        
        }
      })
      .catch(error => {
        rej(error.message)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE INGREDIENT
   *----------------------------------------------------------------------------*/
  async  deleteIngredient({ state, commit, dispatch }, ingredient) {
    commit('deletingIngredientState', ingredient)

    if (state.data.id) {
      state.data.ref.collection('ingredients')
      .doc(ingredient.food.id).delete()
      .then(() => {
        commit('removeIngredient', ingredient)
        commit('removeDeletingIngredientState', ingredient)
        dispatch('showSuccess', 'Ingredient deleted', { root: true })
      })
      .catch(error => {
        console.log(error.message)
      })
    }
    else {
      commit('removeIngredient', ingredient)
      commit('removeDeletingIngredientState', ingredient)
      dispatch('showSuccess', 'Ingredient deleted', { root: true })
    }

  },

  /*------------------------------------------------------------------------------
   * DUPLICATE RECIPE
   *----------------------------------------------------------------------------*/
  duplicateRecipe({ commit, dispatch }, recipe) {
    commit('updateStatus', { duplicating: true })
    commit('setData', _.omit(recipe, ['id', 'ref', 'photo']))
    dispatch('getRecipeIngredients', recipe)
    router.push({ name: 'AddRecipe', query: { duplicate: true } })
  },

  /*------------------------------------------------------------------------------
   * CUSTOMIZE RECIPE
   *----------------------------------------------------------------------------*/
  customizeRecipe({ commit, dispatch }, { recipe, cell = null, meal = null, photo = false }) {

    commit('updateStatus', { added: false })
    commit('updateStatus', { uploadProgress: 0 })
    commit('updateStatus', { adding: false })
    commit('clearOriginalData')
    commit('setOriginalData', recipe)

    if(photo) {
      commit('setData', _.omit(recipe, ['id', 'ref']))

      firebase.storage().ref(`recipes/${recipe.photo}`).getDownloadURL()
      .then((url) => {
          // This can be downloaded directly:
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = () => {
            var blob = xhr.response;
            console.log({ blob })

            commit('setDuplicatePhoto', blob)
          };
          xhr.open('GET', url);
          xhr.send();
      })

    }
    else
      commit('setData', _.omit(recipe, ['id', 'ref', 'photo']))


    if(cell) {
      commit('setCell', cell)
      commit('setMeal', meal)
    }

    dispatch('getRecipeIngredients', recipe)
    router.push({ name: 'CustomizeRecipe', params: { id: recipe.id } })
  },

  /*------------------------------------------------------------------------------
   * DELETE RECIPE ERROR
   * @params
   *  Object
   *    Recipe Object
   *    
   *----------------------------------------------------------------------------*/
  deleteRecipeError({ dispatch }, data) {
    if (data.action) {
      let error = data.recipe.errors.find(e => e == data.error)
      data.recipe.errors.splice(data.recipe.errors.indexOf(error), 1)

      data.recipe.ref
      .update({
        errors: data.recipe.errors
      })
      .then(() => {
        dispatch('showSuccess', 'Issue resolved', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * UPDATE RECIPE FIELD
   * @param
   *  recipe Object
   *  field String
   *  value Any
   *  message String (optional)
   *  silent Boolean (optional)
   *----------------------------------------------------------------------------*/
  async updateRecipeField({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })
    
    await data.recipe.ref
    .update({ [data.field]: data.value })
    .then(() => {
      commit('updateRecipeField', data)
      if (!data.silent) dispatch('showSuccess', data.message || 'Recipe updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
    })
    .finally(() => {
      commit('updateStatus', { updating: false })
    })
  },

  // COMPANY FUNCTIONALITIES

  async addCompany({ commit, dispatch }, payload) {

    let data = payload
    data.updatedAt = Date.now()
    data.createdAt = Date.now()

    await db.collection('companies').add(data)
    .then((docRef) => {
      data.ref = docRef
      data.id = docRef.id

      commit('insertCompany', data)
      dispatch('showSuccess', 'Company added', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', 'Something went wrong in fetching companies', { root: true })
    })
  },

  async getCompanies({ commit, dispatch }) {
    await db.collection('companies')
    .get()
    .then(snapshot => {
      commit('setCompanies', snapshot)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
